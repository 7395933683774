import React from "react";
import NavBar from "../components/NavBar";
import './styles/Projects.css';
import {ContentColumn, TopMost, SummaryWidget} from '../components';
import {Route, Routes} from 'react-router-dom';
import projects from '../data/persoprojects.json';


export default function Projects(props) {
    return (
    <TopMost centerx={true}>
    <NavBar/>
    <Routes>    
        <Route path='/*' element={<LandingPage />} />
        {/* Here add the routes each time I add a new project page */}
    </Routes>
    </TopMost>
    );
    
    
    

}   



function LandingPage() {
    return (    
        <ContentColumn>
            <div>
                <h1>Personal projects</h1>
                <p>
                    In here, you can find my personal project, that I mostly do for fun.
                    Most of those are still works in progress, and the repos will probably
                    very scarcely documented. In the future and as my web-dev skills develop,
                    I plan to add web-apps to play around with the more interactive projects. 
                    If you have any questions about a project,don't hesitate to contact me!
                    You can click on the images to go to the project page, if available.

                    Still in construction !
                </p>
            </div>
            <div>
                <h2>Project list</h2>
                <ProjectList/>
            </div>
        </ContentColumn>);
}

/**
 * Makes a list of projects given the data from persoprojects.json. 
 */
function ProjectList() {
    let projectsArray = [];

    for (let project of projects) {
        const projectCompo = (
            <SummaryWidget
            img_name={project.img_name}
            title={project.title}
            abstract={project.abstract}
            route={project?.route}
            key={project.title}
            />
        );
        projectsArray.push(projectCompo); // Push each Paper component into the array
        }
    
        return (<div className='widget_list'>{projectsArray}</div>)
}