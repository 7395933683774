import React from 'react';


export default function VideoHoverPlay(video, key, className='half-width-video') {
    const handleMouseEnter = (e) => { 
        if(e.target.paused) {
            e.target.play().catch(error => console.error("Error attempting to play video:", error)); 
        }
    };
    const handleMouseLeave = (e) => { 
        if(!e.target.paused) 
            e.target.pause(); 
    };

    return (
        <video
            key={key}
            loop
            muted
            className = {className}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <source src={video} preload="auto" type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    );
}
