import React from 'react';
import { ContentColumn, VideoHoverPlay } from 'components';


const imageVids = require.context('images/courses/alife/', true, /\.(mp4|jpg|jpeg|png|gif)$/);


export default function LeniaPTF() {
    const leniaVids = imageVids.keys().filter(path => path.includes('lenia'));

    return (
    <ContentColumn>
        <h1>Looking for Complexity at Phase Boundaries in Continuous CA</h1>
        <p>In this webpage, you can find a collection of interesting dynamics for the Lenia cellular automaton,
        that have been found with the help of the 'Phase Transition Finder' algorithm, 
        <a href='https://arxiv.org/abs/2402.17848'>introduced in this preprint.</a> </p>
     
        <h3>Lenia dynamics (hover to play)</h3>

        <div className='imageShowcase'>
                {leniaVids.slice(0,leniaVids.length/2).map((path, index) => VideoHoverPlay(imageVids(path), index))} 
        </div>
        <h3><a href="https://github.com/frotaur/TempGECCO">Try the PTF algorithm yourself !</a></h3>
        <div className='imageShowcase'>
                {leniaVids.slice(leniaVids.length/2).map((path, index) => VideoHoverPlay(imageVids(path), index))}
        </div>
    </ContentColumn>);
}


