import React from "react";
import './styles/Teaching.css';
import NavBar from '../components/NavBar.js';
import {ContentColumn, TopMost, SummaryWidget, MiniWidget} from '../components';
import {GeneralRelativity, MathForPhysicists,ArtificialLife} from './Pages';
import {Route, Routes} from 'react-router-dom';
import courses from '../data/courses.json';
import projects from '../data/projects.json';



export default function Teaching() {
    return (

    <TopMost centerx={true}>
    <NavBar/>
    <Routes>    
        <Route path='/*' element={<LandingPage />} />
        <Route path='alife/*' element={<ArtificialLife />} />
        <Route path='relatG' element={<GeneralRelativity />} />
        <Route path='mathPhys' element={<MathForPhysicists />} />
    </Routes>
    </TopMost>
        );

}

function LandingPage() {
    return (
        <ContentColumn>
        <div>
            <h1>Teaching</h1>
            <p>
                You can find here some archived content related to teaching. When available, you can click
                on the image to access the course page which contains the full content. If you feel something is missing,
                or need more information (a solution to an exercise, a clarification ...), feel free to contact me.
                You will also find a small summary of the projects I supervised.
            </p>
        </div>
        
        <div>
            <h2>Courses and exercises</h2>
            <CourseList/>

            <h2>Project supervision</h2>
            <ProjectList/>
            
            <h2>Outreach</h2>
            Under construction...
        </div>
    </ContentColumn>
    )
}

/**
 * Makes a list of courses given the data from courses.json. TODO : change it,
 * because I don't like giving the full resume for the courses I teach. Should be changed to a small list
 * with icons which are images (probably Dall-E generated). They should link to the course page.
 */
function CourseList() {
    let coursesArray = [];

    for (let course of courses) {
        const courseCompo = (
            <MiniWidget
            img_name={course.img_name}
            title={course.title}
            route={course?.route}
            key={course.title}
            />
        );
        coursesArray.push(courseCompo); // Push each Paper component into the array
        }
    
        return (<div className='miniwidget_list'>{coursesArray}</div>)
}

function ProjectList() {
    let projectsArray = [];

    for (let project of projects) {
        const projectCompo = (
            <SummaryWidget
            img_name={project.img_name}
            title={project.title}
            abstract={project.abstract}
            route={project?.route}
            key={project.title}
            />
        );
        projectsArray.push(projectCompo); // Push each Paper component into the array
        }
    
        return (<div className='widget_list'>{projectsArray}</div>)
}