import React from "react";
import {MenuItem, TopMost} from '../components';
import './styles/Home.css'

export default function Home() {
    return (
        <TopMost centerx={true} centery={true} navspace={false}>
        <div>
            <div id="name-div"> Vassilis Papadopoulos</div>
            <div id="menu-div"> 
                <MenuItem target='research' text='Research'/>
                <MenuItem target='teaching' text='Teaching'/>
                <MenuItem target='projects' text='Projects'/>
                <MenuItem target='contact' text='Contact'/>
            </div>
            <div id="minecraft-phrase"> No good on smartphones (yet)</div>
        </div>
        </TopMost>
    );
}