import Research from './Research';
import Teaching from './Teaching';
import Projects from './Projects';
import Contact from './Contact';
import Home from './Home';

import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
  } from "react-router-dom";
  
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
  
function App() {
  return (
    <Router>
        <ScrollToTop />
            <Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/research/*" element={<Research />} />
                <Route path="/teaching/*" element={<Teaching />} />
                <Route path="/projects/*" element={<Projects />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes> 
    </Router>
  );
}


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;
