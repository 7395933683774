import React from 'react';
import { ContentColumn, VideoHoverPlay } from 'components';
import './styles/Teaser.css';

const imageVids = require.context('images/courses/alife/', true, /\.(mp4|jpg|jpeg|png|gif)$/);

/**
 * 
 * Teaser page for the artificial life course.
 */
export default function Teaser() {
    const leniaVids = imageVids.keys().filter(path => path.includes('lenia'));
    const baricelliVids = imageVids.keys().filter(path => path.includes('baricelli'));
    const vonNeumannVids = imageVids.keys().filter(path => path.includes('vonneumann'));
    // console.log(vonNeumannVids)

    return (
    <ContentColumn>
        <h1>Teaser</h1>
        A collection of videos/websites as a teaser for the course, showcasing the beauty and diversity of artificial life. Hover over videos to play them.
        
        <h3><a href="https://oimo.io/works/life/" >Infinite Game of Life </a></h3>
        <h3>Lenia (hover to play)</h3>
        <div className='imageShowcase'>
             {leniaVids.slice(0,leniaVids.length/2).map((path, index) => VideoHoverPlay(imageVids(path), index))} 
        </div>
        <h3><a href="https://github.com/frotaur/TempGECCO">Make your own</a></h3>
        <div className='imageShowcase'>
                {leniaVids.slice(leniaVids.length/2).map((path, index) => VideoHoverPlay(imageVids(path), index))}
        </div>
        <h3><a href="https://orb.farm/">Orb farm</a></h3>
        <h3>Baricelli</h3>
        <div>
            {baricelliVids.slice(0,baricelliVids.length/2).map((path, index) => VideoHoverPlay(imageVids(path), index, 'full-width-video'))}
        </div>
        <h3><a href='https://www.youtube.com/watch?v=shYWK2VhCVs'> A.L.I.E.N. </a></h3>
        <div>
            {baricelliVids.slice(baricelliVids.length/2).map((path, index) => VideoHoverPlay(imageVids(path), index, 'full-width-video'))}
        </div>
        <h3>Von Neumann</h3>
        <div>
            {vonNeumannVids.map((path, index) => VideoHoverPlay(imageVids(path), index, 'full-width-video'))}
        </div>
    </ContentColumn>);
}


