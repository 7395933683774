import React from 'react';
import CourseProject from '../../components/CourseProject';



export default function GeneralRelativity() {
    const abstract = "In this page you find content for the General Relativity M1 course (2020-2022). \
     In 2020-2021, the course was a half-semester long, and taught by Nick Kaiser. From 2021, the course is now \
     a full semester long, and was taught by Marios Petropoulos at ENS. \
     I was in charge of the exercise sessions, for which I designed the exercises sheets.  \
     Some are completely new, most are taken here and there from my previous courses and modified, and \
     other come from the internet. The exercises span all subjects that are taught in a first course of GR, \
     from basics of differential geometry, to the Einstein equations, up to gravitational waves and Penrose diagrams.\
     You can find below all the exercises, as well as the solutions (written by myself). \
     If you find (and you will) typos/errors, please don't hesitate to send an e-mail (see contact section)."

    const GRloc =process.env.PUBLIC_URL + '/GR/'
    var descriptions = ['Special relativity reminders', 'More special relativity', 'Manifolds and index notation',
                        'Parallel transport, more manifolds', 'Fermi local coordinates, geodesics', 'Riemann curvature explorations',
                        'Killing vectors and GR Lagrangian', 'Black hole metric and geodesics', 'Basics of cosmology',
                        'Linear GR and gravitational waves', 'Penrose diagrams', 'Past exams exercises']
    var exercises = descriptions.map((descri,index) => {return {number:index+1, description:descri, exolink:GRloc+`TD${index+1}.pdf`, 
    solutionlink: GRloc+`TD${index+1}_COR.pdf`, sheetname: 'GR_Exos'}})

    
    return (
    <CourseProject title='General Relativity (M1)' abstract={abstract} >
        <h2 style={{alignSelf: 'flex-start'}}>Exercises</h2>
        <ul style={{textAlign:'left',width:'100%'}}>
            <li key='allexos'><a href={GRloc+'GR_Exo_Sol.zip'}>Download all exercises</a></li>
            {exercises.map((ex,index) => <ExerciseLink  {...ex} key={index}/>)}
        </ul>
    </CourseProject>
            );
}


/**
 * Component for a single exercise sheet entry.
 * 
 * @param {string} description The description of the exercise sheet
 * @param {string} exolink The link to the exercise sheet
 * @param {string} solutionlink The link to the solution sheet
 */
function ExerciseLink({number, description, exolink, solutionlink=null,sheetname = null}) {
    let solutionsheetname = null;
    if(!sheetname) {
        sheetname = `Exercises_${number}.pdf`;
        solutionsheetname = `Exercises_${number}_solutions.pdf`
    } else {
        solutionsheetname = `${sheetname}_${number}_solutions.pdf`
        sheetname = `${sheetname}_${number}.pdf`;
    }
    return (<li>
        <a href={exolink} download={sheetname}> Exercise sheet {number}</a> : {description}. 
        {solutionlink? <a href={solutionlink} download={solutionsheetname}> Solutions</a> : null}
        </li>)
}