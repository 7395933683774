import React from "react";
import './styles/Research.css';
import {ContentColumn, TopMost, SummaryWidget,NavBar} from 'components';
import papers from 'data/papers.json';
import {Route, Routes} from 'react-router-dom';
import {LeniaPTF} from './Pages/Research'

export default function Research() {
    return (
        <TopMost centerx={true}>
            <NavBar/>
            <Routes>    
                <Route path='/*' element={<LandingPage />} />
                <Route path='leniaptf' element={<LeniaPTF />} />
            </Routes>
        </TopMost>
            );
}

function LandingPage() {
    let paperArray = [];

    for (let paper of papers) {
        const paperCompo = (
            <SummaryWidget
            img_name={paper.img_name}
            title={paper.title}
            abstract={paper.abstract}
            route={paper?.link}
            local={paper?.local ?? false}
            key={paper.title}
            />
        );
        paperArray.push(paperCompo); // Push each Paper component into the array
        }
    return ( 
        <ContentColumn>
            <h1>Research</h1>
            <p>
                Here, you can find abstracts and links to my papers below. Although that may not be immediately obvious from the paper list, my current research interests
                are in the field of Artificial Life. In particular, I am trying to develop methods (often leveraging tools from deep learning) to automatically search for 'interesting'
                Artificial Life systems and configurations, whatever the definition of 'interesting' may be. In particular, I am aiming at algorithms that are system-agnostic, i.e. not limited to a single
                platform (be it Lenia, Game of Life, or something else). The hope would be then to generate a large dataset of interesting systems, which might help develop a more theoretical understanding of
                the emergence of complexity, and ultimately, of life.
            </p>
            <p>
                Before my post-doc, my research was in theoretical high-energy physics. In particular, I was fascinated by the Black Hole Information Paradox, and took an interest in the recent advances in 2019
                with the Island proposal. This lead me to the study of toy models of Holography in the presence of domain walls, with goal to better understand the setups in which the Island proposal was formulated. 
                Although I am still passionate about this field, the day-to-day research did not fit me as well as I thought it would, and that is why I decided to (radically) change my research direction.
                I do still open the Polchinski regularly, browse /hep-th from time to time, and teach QFT courses, and I am very lucky to be able to keep up with the field through discussion with my physicist colleagues.
            </p>
            <h1>Papers</h1>
            <div className="paper-box">
            {paperArray}
            </div>
        </ContentColumn>);
}