import React from "react";

export default function TopMost({children, centerx=false,centery=false, navspace=true}) {
    const stylish = {
        display: "flex",
        flexDirection: "column",
        justifyContent: centerx ? "center" : "flex-start",
        alignItems: centery ? "center" : "flex-start",
        width: "100%",
        minHeight: navspace ? "calc(100vh - var(--navbar-height))" : "100vh",
        marginTop: navspace ? "var(--navbar-height)" : "0px",
        }
    return (<div className="topMost" style={stylish}>
                {children}
            </div>);
}