import React from 'react';
import './styles/SummaryWidget.css';
import { Link } from 'react-router-dom';

// Create a context for all images in the papers directory
const imageContext = require.context('../images', true, /\.(jpg|jpeg|png|gif)$/);

/**
 * Just like the Paper component, but the link is internal.
 * 
 * @param {String} img_name The name of the image file
 * @param {String} title The title of the paper
 * @param {String} abstract The abstract of the paper
 * @param {String} route The route to the paper. If null, no link
 * @param {Boolean} local If true, the link is internal. If false, the link is external
 */
export default function SummaryWidget({img_name, title, abstract, route=null, local=true}) {
    const img_path = imageContext(`./${img_name}`);

    const image = <img className="imagus" src={img_path} alt={title}/>;
    const linked_image = route ? (local ? <Link to={route}>{image}</Link> : <a href={route}>{image}</a>) : image;
    return (<div className="paper-widget">
                <div className="paper-img">
                    {linked_image}
                </div>
                <div className="paper-text">
                    <h1 id='title'>{title}</h1>
                    <p id='abstract'>{abstract}</p>
                </div>
            </div>);
}

/**
 * Just like the Paper component, but the link is internal.
 * 
 * @param {String} img_name The name of the image file
 * @param {String} title The title of the paper
 * @param {String} abstract The abstract of the paper
 * @param {String} route The route to the paper. If null, no link
 * @param {Boolean} local If true, the link is internal. If false, the link is external
 */
export function MiniWidget({img_name, title, route=null, local=true}) {
    const img_path = imageContext(`./${img_name}`);

    const image = <img className="imagus" src={img_path} alt={title}/>;

    const widget = (<div className="mini-widget">
                        <div className="mini-img">
                            {image}
                        </div>
                        <div className="mini-text">
                            <h1 id='title'>{title}</h1>
                        </div>
                    </div>)
    const linked_widget = route ? (local ? <Link className='whiteLink' to={route}>{widget}</Link> : <a className='whiteLink' href={route}>{widget}</a>) : widget;

    /* Encapsulate in a div with 100% width to avoid weird behavior */
    return (<div style={{width: '100%',margin: '0px'}}>
                {linked_widget}
            </div>);
}