import React from 'react';
import CourseProject from '../../components/CourseProject';



export default function MathForPhysicists() {
    const abstract =  "This is the page for the course 'Mathematical Methods for Physicists', a third year undergraduate course at ENS, which was taught in French. \
    Together with Guilhem Semerjian, I was in charge of the exercises sessions for two years (2020-2022). \
    The exercises are very varied as the aim of the course was to give students a broad overview of the mathematical tools used in physics. \
    There is a big compendium of exercises, of which only a few were solved in the exercise sessions.\
    The exercises are not yet available here, but I will see if I can redistribute them. For now, you can find below \
    a few notes I gave the students on very specific topics, which they wanted to know more about. "

    const MPloc =process.env.PUBLIC_URL + '/MP/'
    var descriptions = []
    var exercises = descriptions.map((descri,index) => {return {number:index+1, description:descri, exolink:MPloc+`Note${index+1}.pdf`, 
    solutionlink: null, sheetname: 'Note_MP'}})

    
    return (
    <CourseProject title='Mathematics for Physicists (M1)' abstract={abstract} >
        <h2 style={{alignSelf: 'flex-start'}}>Documents</h2>
        <ul style={{textAlign:'left',width:'100%'}}>
            Under construction...
            {/* <li><a href={GRloc+'GR_Exo_Sol.zip'}>Download all exercises (maybe coming soon)</a></li> */}
            {exercises.map((ex,index) => <ExerciseLink  {...ex} key={index}/>)}
        </ul>
    </CourseProject>
            );
}


/**
 * Component for a single exercise sheet entry.
 * 
 * @param {string} description The description of the exercise sheet
 * @param {string} exolink The link to the exercise sheet
 * @param {string} solutionlink The link to the solution sheet
 */
/* TODO : extract ExerciseLink to a separate component */

function ExerciseLink({number, description, exolink, key,solutionlink=null,sheetname = null}) {
    let solutionsheetname = null;
    if(!sheetname) {
        sheetname = `Exercises_${number}.pdf`;
        solutionsheetname = `Exercises_${number}_solutions.pdf`
    } else {
        solutionsheetname = `${sheetname}_${number}_solutions.pdf`
        sheetname = `${sheetname}_${number}.pdf`;
    }
    return (<li key={key}>
        <a href={exolink} download={sheetname}> Exercise sheet {number}</a> : {description}. 
        {solutionlink? <a href={solutionlink} download={solutionsheetname}> Solutions</a> : null}
        </li>)
}