import React from 'react';
import CourseProject from '../../components/CourseProject';
import {Route, Routes, Link} from 'react-router-dom';
import {Teaser} from './AlifeStuff';


export default function ArtificialLife() {
    return (
        <Routes>    
            <Route path='/' element={<ArtificialLifeMain />} />
            <Route path='teaser' element={<Teaser />} />
        </Routes>
    );
}


function ArtificialLifeMain() {
    const abstract = `In this page you find content for the Artificial Life course (2023). \
    Although it is technically a PhD course in the Math department, there are no hard requisites, and it is suitable and even intended also for students in Biology, Physics, and Computer Science. \
    The goal of the course it to given an overview of the field of Artificial Life, revisiting some of the classic theoretical results and models. In the latter half of the course, emphasis will be put \
    on description and implementations of more recent models in code. In this page you will find recordings and slide of the lectures, as well as any other material used in class.`

    const live_link = 'https://www.youtube.com/channel/UCmkk4Zv4Jom0yRd7L-Dx_FQ/live'
    const chan_link = 'https://www.youtube.com/channel/UCmkk4Zv4Jom0yRd7L-Dx_FQ'
    const livepart = (<p>{`The course takes place every Wednesday, at 14:15. It is streamed live on youtube, at `}<a href={live_link}>this link.</a> (You can also find the stream by 
    navigating to the youtube channel <a href={chan_link}>AlifeEPFL</a>, the livestream should appear if we are live). You can also 
    join the polls and Q&A on slido.com, the number to join is posted at the bottom of the list every week.</p>)

    var descriptions = ['What is Alife ? Historical perspective','The Von Neumann CA', 'Turing Machines', 'Self-reproducing programs and quines',
                        'Quine continued and Wolfram classes, Gardens of Eden', 'Wolfram classes, Gardens of Eden continued', 'More discrete CAs, Barricelli, LGCA, MNCA',
                        'Reaction-diffusion models', 'Lenia and friends', 'Neural Cellular Automata', 'The KdV equation and solitons', 'Particle and Agent-based models',
                        'Wrap up and perspectives']
    var courselinks = ['https://frotaur.notion.site/Course-1-Introduction-0ffe3e9518ad43b8afbf004e1d9afc6c?pvs=74',
                        'https://frotaur.notion.site/Course-2-Von-Neumann-CA-433a9d36f8104935b2b4db4f8cb40675?pvs=74',
                        'https://frotaur.notion.site/Course-3-Turing-Machines-4d2aa5e95b09426f9b9af12ddf39d299?pvs=4',
                        'https://frotaur.notion.site/Course-4-Self-reproduction-quines-c12d3a4ee91149ddb43fce6e6e08f729?pvs=4',
                        'https://frotaur.notion.site/Course-5-Binary-CAs-Wolfram-classes-Toffoli-9e2a7b5a1c114efdb48567a0d77ea6db?pvs=4',
                        'https://frotaur.notion.site/Course-5-Binary-CAs-Wolfram-classes-Toffoli-9e2a7b5a1c114efdb48567a0d77ea6db?pvs=4',
                        'https://frotaur.notion.site/Course-6-Barricelli-2D-CA-LGCA-efbcc03cbeac4f1090ed32bf73bf15ac?pvs=4',
                        'https://frotaur.notion.site/Course-7-Reaction-diffusion-models-135da1be5974423aa38749dcc0b9de15?pvs=74',
                        'https://frotaur.notion.site/Course-8-Lenia-and-friends-6264f0e3e0b84dcca8badb2e640fc517?pvs=4',
                        'https://frotaur.notion.site/Course-10-Neural-Cellular-automata-63d6eb2efe9443b4b2c3a09a55f493a0?pvs=4',
                        'https://frotaur.notion.site/Course-10-The-KdV-equations-and-rigorous-solitons-1abb3c9606df48d19282a09395b110d1?pvs=4',
                        'https://frotaur.notion.site/Course-11-Agent-models-boids-slime-molds-and-more-c66ba8e44d5d401586c40beadfabdcd7?pvs=4',
                        'https://frotaur.notion.site/Course-12-Artificial-Life-today-1ed68c3821cc46ba8c01d8121ffa5ab6?pvs=4']
    var youtubelinks = ['https://youtu.be/K-OYg_xEvVc', 'https://youtu.be/xFm32wciys4', 'https://youtu.be/WC5d3rTZnWw', 'https://youtu.be/11LgKlqm_r0',
                        'https://youtu.be/JfWTdNID7yY', 'https://youtu.be/-_JNJpfrwRc','https://youtu.be/yCMecpXsTSc', 'https://youtu.be/g4YR62VkfnU',
                        'https://youtu.be/nMoQqppLcM4', 'https://youtu.be/_ealiM25biA', '', 'https://youtu.be/uS3aYX9vCfA', 'https://youtu.be/F7Zd4plMejk']
    var courses = descriptions.map((descri,index) => {return {number:index+1, description:descri, 
        courselink:courselinks[index], youtubelink:youtubelinks[index]}})

    
    return (
    <CourseProject title='Artificial Life' abstract={abstract} >
        {livepart}
        <h2>Miscellaneous links</h2>
        <ul style={{textAlign:'left',width:'100%'}}>
            <li> <Link to='teaser'>Teaser</Link> </li>
            <li> <a href= 'https://github.com/frotaur/PyCA'>PyCA repo</a>, for the implementation of 2D Alife systems. <a href='https://youtu.be/SIIgRlBUXSs'>Video tutorial</a></li>
        </ul>
        <h2 style={{alignSelf: 'flex-start'}}>Past Lectures</h2>
        <ul style={{textAlign:'left',width:'100%'}}>
            {courses.map((ex,index) => <CourseLink  {...ex} key={index}/>)}
        </ul>

    </CourseProject>
            );

}


// /**
//  * Component for a single course entry.
//  * 
//  * @param {string} description The description of the course
//  * @param {string} courselink The link to the course page
//  */
function CourseLink({number, description, courselink,youtubelink}) {
    return (<li>
         Lecture {number}: <a href={courselink}>{description}</a>. {youtubelink ? <a href={youtubelink}>Recorded Lecture Link</a>: 'Recorded Lecture Coming Soon'}.
        </li>)
}

function LiveLink({description, link, slidonum, notionlink}) {
    return (<li>
            {description} : <a href={link}>Live Youtube link</a>. <a href={notionlink}>Link to Notion notebook</a>. Slido.com number to join polls: {slidonum} 
        </li>)
}