import React from "react";
import './styles/NavBar.css';
import {Link} from 'react-router-dom';

export default function NavBar() {
    return (<div className="navBar">
                <div className="home_button">
                    <Link to="/">Vassilis Papadopoulos</Link>
                </div>
                <ul>
                    <li><Link to="/research">Research</Link></li>
                    <li><Link to="/teaching">Teaching</Link></li>
                    <li><Link to="/projects">Projects</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>
            </div>);
}