import React from "react";
import NavBar from "../components/NavBar";
import './styles/Contact.css';
import twitter from'../images/twitter.png';
import github from'../images/github.png';
import mail from'../images/mail.png';
import admonymous from'../images/admonymous.png';
import {TopMost} from '../components';
import { useState } from 'react';


export default function Contact(props) {
    return (<TopMost centerx={true} centery={true}>
                <NavBar/>
                <div id="contact_pane">
                    <a href="https://twitter.com/VassPapas">
                        <img className="contact_img" src={twitter} alt="Twitter" />
                    </a>
                    <a href="https://github.com/frotaur">
                        <img className="contact_img" src={github} alt="Github" />
                    </a>
                    <a href="https://www.admonymous.co/vass"> 
                        <img className="contact_img" src={admonymous} alt="Admonymous" />
                    </a>
                    <EmailCopy />
                </div>
            </TopMost>);
}


function EmailCopy(props) {
    const [showMessage, setShowMessage] = useState(false);

    async function handleEmailClick(event){
        try {
            event.preventDefault();
            await navigator.clipboard.writeText('vassilis.physics@gmail.com');
            setShowMessage(true);

            // Wait for the fade out transition to complete
            await new Promise(resolve => setTimeout(resolve, 2000));
            setShowMessage(false);
        } catch (err) {
            console.error('Error copying text: ', err);
        }
    };

    return (
        <div className="iconMessage">
            <button onClick={handleEmailClick} className="contact_img_button">
                <img className="contact_img" src={mail} alt="Email Icon"  />
            </button>
            {showMessage && 
            (<div className="copyMessage fading">
                Copied to clipboard!
            </div>)}

        </div>
    );

}