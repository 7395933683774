import React from 'react';
import ContentColumn from './ContentColumn';


/**
 * Component wrapper for a course/project page. For now I assume that the 
 * TopMost and Navbar components are already present in the mother page. 
 * We will see if we change that, but I don't think so.
 * 
 * @param {string} title The title of the course/project
 * @param {string} abstract The abstract of the course/project
 */

export default function CourseProject({title, abstract,children}) {
    return (
            <ContentColumn>
                <h1>{title}</h1>
                <p>{abstract}</p>
                {children}
            </ContentColumn>
            )
}